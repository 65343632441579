import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';
import {filter, map, pluck, takeUntil} from 'rxjs/operators';
import {UserTagModel} from './core/models/user-tag.model';
import {UserTagsService} from './core/service/user-tags.service';

declare var yatranslate: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  languages = [
    {image: 'https://cdn.gtranslate.net/flags/svg/ru.svg', text: 'Русский', code: 'ru'},
    {image: 'assets/img/tat.svg', text: 'Татар', code: 'tt'}, //TODO
    {image: 'https://cdn.gtranslate.net/flags/svg/en.svg', text: 'English', code: 'en'},
    {image: 'https://cdn.gtranslate.net/flags/svg/hi.svg', text: 'हिन्दी', code: 'hi'},
    {image: 'https://cdn.gtranslate.net/flags/svg/zh-TW.svg', text: '繁體中文', code: 'zh'},
    {image: 'https://cdn.gtranslate.net/flags/svg/tr.svg', text: 'Türkçe', code: 'tr'},
    {image: 'https://cdn.gtranslate.net/flags/svg/pt.svg', text: 'Português', code: 'pt'},
    {image: 'https://cdn.gtranslate.net/flags/svg/es.svg', text: 'Español', code: 'es'},
    {image: 'https://cdn.gtranslate.net/flags/svg/ar.svg', text: 'العربية', code: 'ar'},
    {image: 'https://cdn.gtranslate.net/flags/svg/fa.svg', text: 'فارسی', code: 'fa'},
    {image: 'https://cdn.gtranslate.net/flags/svg/de.svg', text: 'Deutsch', code: 'de'},
    {image: 'https://cdn.gtranslate.net/flags/svg/fr.svg', text: 'Français', code: 'fr'},
    {image: 'https://cdn.gtranslate.net/flags/svg/it.svg', text: 'Italiano', code: 'it'}
  ];

  selectedLang = this.languages[0];
  languagesListVisible = false; // Флаг для отображения/скрытия списка опций


  private _destory$ = new Subject();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly userTagsService: UserTagsService
  ) {
  }

  ngOnInit(): void {

    const code = this.yaTranslateGetCode();
    if(code) {
      this.selectedLang = this.languages.filter(x=>x.code == code)[0] ?? this.languages[0];
    }

    this.activatedRoute.queryParams
      .pipe(
        takeUntil(this._destory$),
        map(({client, sale}) => ({
          ...(client && {client_id: client}),
          ...(sale && {sale}),
        })),
        filter((user: UserTagModel) => Object.keys(user).length > 0)
      )
      .subscribe((user: UserTagModel) => this.userTagsService.set(user));
  }


  ngOnDestroy(): void {
    this._destory$.next();
    this._destory$.complete();
  }

  yaTranslateGetCode() {
    return (localStorage["yt-widget"] != undefined && JSON.parse(localStorage["yt-widget"]).lang != undefined) ? JSON.parse(localStorage["yt-widget"]).lang : yatranslate.lang;
  }

  onSelectOption(option: any): void {
    this.selectedLang = option;
    this.toggleOptionsList(); // Скрыть список опций после выбора

    localStorage.setItem('yt-widget', JSON.stringify({
      "lang": option.code,
      "active": true
    }));
    window.location.reload();
  }

  toggleOptionsList(): void {
    this.languagesListVisible = !this.languagesListVisible;
  }
}
