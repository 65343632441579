import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainInterceptor } from './core/service/token.interceptor';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {NgxMaskDirective, provideEnvironmentNgxMask} from "ngx-mask";
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
    }),
    AppRoutingModule,
    BsDatepickerModule.forRoot(),

  ],
  providers: [
    provideEnvironmentNgxMask(),
    Meta,
    { provide: HTTP_INTERCEPTORS, useClass: MainInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
