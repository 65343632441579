import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

export abstract class BaseState<T> {
  get data$() {
    return this._data$.asObservable().pipe(distinctUntilChanged());
  }

  get data() {
    return this._data$.value;
  }

  set(value: T) {
    this._data$.next(value);
  }

  private readonly _data$: BehaviorSubject<T>;

  constructor(initData: T = null as any) {
    this._data$ = new BehaviorSubject(initData);
  }
}
