import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BusinessModel } from '../models/business.model';
import { FeedbackModel } from '../models/feedback.model';

import { BaseState } from './abstract-base-state.service';
import { ApiService } from './api.service';
import { UserTagsService } from './user-tags.service';

@Injectable({
  providedIn: 'root',
})
export class BusinessStateService extends BaseState<BusinessModel> {
  constructor() {
    super();
  }
}

@Injectable({
  providedIn: 'root',
})
export class BusinessAPIService {
  constructor(private readonly apiService: ApiService) {}
  fetch(id: number | string): Observable<BusinessModel> {
    return this.apiService.get(`public/establishment/${id}`);
  }

  fetchInfo(id: number | string) {
    return this.apiService.get(`public/company-info/${id}`);
  }

  sendFeedback({
    id,
    payload,
  }: {
    id: number | string;
    payload: FeedbackModel;
  }) {
    return this.apiService.post(
      `public/feedback`,
      { "establishment_id": id, ...payload}
    );
  }

  sendTips({
    id,
    amount,
  }: {
    id: number | string;
    amount: number;
  }) {
    return this.apiService.post(
      `public/leave-tips`,
      {
        "amount": amount,
        "establishmentId": id
      }
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class BusinessService {
  public get data() {
    return this.state.data;
  }

  public get data$() {
    return this.state.data$;
  }

  constructor(
    private readonly state: BusinessStateService,
    private readonly api: BusinessAPIService,
    private readonly userTags: UserTagsService
  ) {}

  fetch(id: number | string): Observable<BusinessModel> {
    return this.api
      .fetch(id)
      .pipe(tap((business: BusinessModel) => this.state.set(business)));
  }

  fetchInfo(id: number | string) {
    return this.api
      .fetchInfo(id);
  }

  sendFeedback({
    id,
    payload,
  }: {
    id: number | string;
    payload: FeedbackModel;
  }) {
    return this.api.sendFeedback({
      id,
      payload: { ...payload, ...this.userTags.data },
    });
  }

  sendTips({
    id,
    amount
  }: {
    id: number | string;
    amount: number;
  }) {
    return this.api.sendTips({
      id,
      amount,
    });
  }


}
