import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BusinessService } from './business.service';

@Injectable()
export class MainInterceptor implements HttpInterceptor {

    constructor(private businessService: BusinessService) {
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // console.log("MainInterceptor", this.businessService.data)
        if (this.businessService.data && localStorage.getItem(`user_${this.businessService.data.id}`)) {
            let lsu: any = localStorage.getItem(`user_${this.businessService.data.id}`)
            if(lsu) {
                let token = JSON.parse(lsu)['token'] 
                if(token.length > 0) {
                    request = request.clone({
                        setHeaders: {
                            Authorization: `${token}`
                        }
                    });
                }
            }
            
        }
        return next.handle(request);
    }
}
