import { Injectable } from '@angular/core';
import { UserTagModel } from '../models/user-tag.model';
import { BaseState } from './abstract-base-state.service';

@Injectable({
  providedIn: 'root',
})
export class UserTagsService extends BaseState<UserTagModel> {
  constructor() {
    super({});
  }
}
