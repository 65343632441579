<router-outlet></router-outlet>


<div class="lang" translate="no">
  <div class="lang-select">
    <div class="lang-select-list" *ngIf="languagesListVisible">
      <ul class=" lang-select-list_scroll">
        <li class="lang-select-list__option lang-select__option" *ngFor="let lang of languages"
            (click)="onSelectOption(lang)">
          <img class="lang-select__img" [src]="lang.image">
          <span class="lang-select__span">{{ lang.text }}</span>
        </li>
      </ul>
    </div>
    <div class="lang-select__option lang-select__option_active" (click)="toggleOptionsList()">
      <img class="lang-select__img" [src]="selectedLang.image">
      <span class="lang-select__span">{{ selectedLang.text }}</span>
    </div>
  </div>
</div>

